import { Injectable } from '@angular/core';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private oauthService: OAuthService,
    private router: Router
  ) {}

  async init(): Promise<void> {
    const authCodeFlowConfig: AuthConfig = {
      issuer: environment.oAuthIssuerUrl,
      redirectUri: window.location.origin,
      clientId: environment.oAuthClientId,
      responseType: 'code',
      scope: 'openid profile email offline_access',
      oidc: true,
    };
    this.oauthService.configure(authCodeFlowConfig);
    this.oauthService.setupAutomaticSilentRefresh();

    // this.oauthService.events.subscribe(e => {

    //   if (e.type === 'token_received') {

    //     if (this.oauthService.state) {
    //       const redirectUri =  decodeURIComponent(this.oauthService.state).replace(/\/+$/, '');

    //       if (redirectUri) {
    //         this.router.navigateByUrl(redirectUri);
    //       }
    //     }
    //   }
    // });

    await this.oauthService.loadDiscoveryDocumentAndTryLogin().then(() => {
      if (this.oauthService.state) {
        const redirectUri = decodeURIComponent(this.oauthService.state).replace(/\/+$/, '');

        if (redirectUri) {
          this.router.navigateByUrl(redirectUri);
        }
      }
     
    });
  }

  isLoggedIn(): boolean {
    return this.oauthService.getIdentityClaims() != null;
  }

  getLoggedInUserName(): string | null {
    if (!this.oauthService.getIdentityClaims()) {
      return null;
    }
    return this.oauthService.getIdentityClaims().preferred_username;
  }

  getLoggedInUserRoles(): string[] | null {
    if (!this.oauthService.getIdentityClaims()) {
      return null;
    }
    return this.oauthService.getIdentityClaims().realm_access['roles'].map((role: string) => role.toUpperCase());
  }

  login() {
    const currentUri = window.location.pathname + window.location.search;
    const additionalState = currentUri !== '/' ? currentUri : undefined;
    this.oauthService.initLoginFlow(additionalState);
  }

  logout(): void {
    this.oauthService.logOut();
  }

  hasTesterCredentials(): boolean {
    return localStorage.getItem('tester-credentials') != null;
  }

  getTesterCredentials(): string {
    return localStorage.getItem('tester-credentials') ?? '';
  }

  updateTesterCredentials(testerCredentials: string): void {
    localStorage.setItem('tester-credentials', testerCredentials);
  }
}
