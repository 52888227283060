<div class="row p-3 pb-4">
  <h3 class="mb-0">Regionen</h3>
</div>
<ul>
  <li
    *ngFor="let region of regions"
    class="row"
    [ngStyle]="{ 'background-color': region.id === selectedRegion?.id ? '#73c7af' : '#ffffff' }">
    <div style="width: 100%" (click)="onRegion(region)">{{ region.id }}</div>
  </li>
</ul>
