<div class="row p-3 pb-4">
  <h3 class="mb-0">Region bearbeiten</h3>
  <button type="button" class="close ml-auto" (click)="onCloseClicked()">
    <span>&times;</span>
  </button>
</div>
<form [formGroup]="regionForm">
  <div class="form-group row">
    <label for="regionId" class="col-sm-3 col-form-label">Name</label>
    <div class="col-sm-9">
      <input type="text" class="form-control" id="regionId" formControlName="id" />
      <small class="text-danger" *ngIf="regionForm.get('id')?.hasError('required')">
        Fehler: Dieses Feld wird benötigt.
      </small>
      <small class="text-danger" *ngIf="regionForm.get('id')?.hasError('duplicate')">
        Fehler: Name schon in Verwendung.
      </small>
    </div>
  </div>

  <div class="form-group row">
    <label for="regionRadius" class="col-sm-3 col-form-label">Radius</label>
    <div class="col-sm-9">
      <input type="number" step="10" class="form-control" id="regionRadius" formControlName="radius" />
      <small class="text-danger" *ngIf="this.regionForm.get('radius')?.hasError('min')">
        Fehler: Radius ist zu klein.
      </small>
    </div>
  </div>

  <hr />

  <div class="form-group row">
    <label for="regionLongitude" class="col-sm-3 col-form-label">Longitude</label>
    <div class="col-sm-9">
      <input type="number" step="10" class="form-control" id="regionLongitude" formControlName="long" />
    </div>
  </div>
  <div class="form-group row">
    <label for="regionLatitude" class="col-sm-3 col-form-label">Latitude</label>
    <div class="col-sm-9">
      <input type="number" step="10" class="form-control" id="regionLatitude" formControlName="lat" />
    </div>
  </div>

  <hr />

  <div class="form-group row">
    <label for="regionLatitude" class="col-sm-6 col-form-label">View-Rechteck</label>

    <div class="col-sm-6">
      <button class="ml-auto btn btn-light border shadow-none text-primary" (click)="onResetViewRectClicked()">
        Rechteck Zurücksetzen
      </button>
    </div>
  </div>

  <div class="form-group row">
    <label for="regionLatitude" class="col-sm-12 col-form-label">Ecke oben rechts:</label>
  </div>

  <div class="form-group row">
    <div class="col-sm-6">
      <input type="number" step="10" class="form-control" formControlName="vb1Lng" />
    </div>
    <div class="col-sm-6">
      <input type="number" step="10" class="form-control" formControlName="vb1Lat" />
    </div>
  </div>

  <div class="form-group row">
    <label for="regionLatitude" class="col-sm-12 col-form-label">Ecke unten links:</label>
  </div>

  <div class="form-group row">
    <div class="col-sm-6">
      <input type="number" step="10" class="form-control" formControlName="vb2Lng" />
    </div>
    <div class="col-sm-6">
      <input type="number" step="10" class="form-control" formControlName="vb2Lat" />
    </div>
  </div>

  <div class="row p-3">
    <div class="col">
      <button class="ml-auto btn btn-light border shadow-none text-primary" (click)="onCloseClicked()">
        Schließen
      </button>
    </div>
    <div class="col">
      <button
        *ngIf="!allowDelete"
        class="ml-auto btn btn-light border shadow-none text-primary disabled"
        style="cursor: default"
        tooltip="Region kann nicht gelöscht werden da sie noch von Karten referenziert wird."
        placement="left">
        Region löschen
      </button>
      <button
        *ngIf="allowDelete && !regionForm.valid"
        class="ml-auto btn btn-light border shadow-none text-primary disabled"
        style="cursor: default"
        tooltip="Region mit ungültigen Feldern kann nicht gelöscht werden."
        placement="left">
        Region löschen
      </button>
      <button
        *ngIf="allowDelete && regionForm.controls.id.valid"
        class="ml-auto btn btn-light border shadow-none text-primary"
        (click)="onDeleteClicked()">
        Region löschen
      </button>
    </div>
  </div>
</form>
