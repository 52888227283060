import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { Map } from 'leaflet';
import { MapConfigModel } from '../../models/map-config.model';
import { CaseCard, CaseRegion, CaseSpecModel } from '../../models/case-spec.model';

@Component({
  selector: 'mima-case-editor',
  templateUrl: './case-editor.component.html',
  styleUrls: ['./case-editor.component.scss'],
})
export class CaseEditorComponent implements OnChanges {
  TITLE_CARD_INDEX = undefined;

  @Input()
  mapConfig: MapConfigModel | undefined | null;

  @Input()
  caseSpec: CaseSpecModel = new CaseSpecModel({});

  @Output() caseSpecChange = new EventEmitter<CaseSpecModel>();

  caseForm = new UntypedFormGroup({
    title: new UntypedFormControl('', Validators.required),
    difficulty: new UntypedFormControl(2, [Validators.required, Validators.min(1), Validators.max(5)]),
    tags: new UntypedFormControl([]),
  });

  cardForm = new UntypedFormGroup({
    viewRegion: new UntypedFormGroup({
      regionId: new UntypedFormControl('', Validators.required),
    }),

    frontTitle: new UntypedFormControl('', Validators.required),
    hint: new UntypedFormControl(''),
    backText: new UntypedFormControl(''),
    solutions: new UntypedFormArray([]),
    caseMarkers: new UntypedFormArray([]),
    tooltips: new UntypedFormArray([]),
    backFollowups: new UntypedFormArray([]),
    tags: new UntypedFormControl([]),
  });

  titleCardForm = new UntypedFormGroup({
    viewRegion: new UntypedFormGroup({
      regionId: new UntypedFormControl('', Validators.required),
    }),

    backText: new UntypedFormControl('', Validators.required),
  });

  selectedCardIndex: number | undefined = this.TITLE_CARD_INDEX;

  selectedRegionIndex: number | undefined;
  selectedRegion: CaseRegion | undefined;

  clickRegionMapViewMap: Map | undefined;

  constructor(private ref: ChangeDetectorRef) {
    this.caseForm.valueChanges.subscribe((value: any) => this.onCaseFormChanged(value));
    this.cardForm.valueChanges.subscribe((value: any) => this.onCardFormChanged(value));
    this.titleCardForm.valueChanges.subscribe((value: any) => this.onTitleCardFormChanged(value));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.caseSpec) {
      this.selectedCardIndex = undefined;
      this.selectedRegionIndex = undefined;
      this.selectedRegion = undefined;
      this.caseForm.setValue(
        {
          title: this.caseSpec.description.title,
          difficulty: this.caseSpec.description.difficulty,
          tags: this.caseSpec.tags,
        },
        { emitEvent: true }
      );
      this.titleCardForm.patchValue(
        {
          viewRegion: this.caseSpec.titleCard.viewRegion,
          backText: this.caseSpec.titleCard.backText,
        },
        { emitEvent: true }
      );
      this.updateControls();
    }
  }

  // TODO: test this!
  isRegionDeletable(region: CaseRegion): boolean {
    let regionsInUse = this.caseSpec.cards.flatMap(card => [
      ...card.solutions.flatMap(solution => solution.regionIds),
      ...card.backFollowups.flatMap(followup => (followup.viewRegion ? [followup.viewRegion.regionId] : [])),
      ...card.caseMarkers.flatMap(caseMarker => caseMarker.regionId),
      ...card.tooltips.flatMap(tooltip => tooltip.regionIds),

      card.viewRegion ? card.viewRegion.regionId : '',
    ]);

    regionsInUse = [
      ...regionsInUse,
      this.caseSpec.titleCard.viewRegion ? this.caseSpec.titleCard.viewRegion.regionId : '',
    ];

    return !regionsInUse.some(id => {
      return id === region.id;
    });
  }

  getNonSelectedRegionIds(): string[] {
    return this.caseSpec.regions
      .filter(
        region =>
          region.id !==
          (this.selectedRegionIndex === undefined ? -1 : this.caseSpec.regions[this.selectedRegionIndex].id)
      )
      .map(region => region.id);
  }

  onCaseFormChanged(value: any): void {
    if (this.caseForm.valid) {
      this.caseSpec.description.title = value.title;
      this.caseSpec.description.difficulty = value.difficulty;
      this.caseSpec.tags = value.tags;
      this.caseSpecChange.emit(this.caseSpec);
    }
  }

  onCardFormChanged(value: any): void {
    if (this.selectedCardIndex !== undefined) {
      this.caseSpec.cards[this.selectedCardIndex] = value;

      this.caseSpec.cards[this.selectedCardIndex].viewRegion = this.caseSpec.cards[this.selectedCardIndex].viewRegion
        ?.regionId
        ? this.caseSpec.cards[this.selectedCardIndex].viewRegion
        : null;

      this.caseSpec.cards[this.selectedCardIndex].backFollowups.map(backFollowup => {
        backFollowup.viewRegion = backFollowup.viewRegion?.regionId ? backFollowup.viewRegion : null;
      });

      this.caseSpecChange.emit(this.caseSpec);
    }
  }

  onTitleCardFormChanged(value: any): void {
    this.caseSpec.titleCard = value;

    this.caseSpec.titleCard.viewRegion = this.caseSpec.titleCard.viewRegion?.regionId
      ? this.caseSpec.titleCard.viewRegion
      : null;

    this.caseSpecChange.emit(this.caseSpec);
  }

  updateControls(): void {
    if (this.caseSpec.cards && this.selectedCardIndex !== undefined) {
      const card = this.caseSpec.cards[this.selectedCardIndex];

      if (card.solutions) {
        (this.cardForm.get('solutions') as UntypedFormArray).clear({ emitEvent: false });
        card.solutions?.forEach(() => {
          (this.cardForm.get('solutions') as UntypedFormArray).push(
            new UntypedFormGroup({
              text: new UntypedFormControl('', Validators.required),
              regionIds: new UntypedFormControl([], Validators.required),
            }),
            { emitEvent: false }
          );
        });
      }

      if (card.backFollowups) {
        (this.cardForm.get('backFollowups') as UntypedFormArray).clear({ emitEvent: false });
        card.backFollowups?.forEach(() => {
          (this.cardForm.get('backFollowups') as UntypedFormArray).push(
            new UntypedFormGroup({
              text: new UntypedFormControl('', Validators.required),
              viewRegion: new UntypedFormGroup({
                regionId: new UntypedFormControl('', Validators.required),
              }),
            }),
            { emitEvent: false }
          );
        });
      }

      if (card.tooltips) {
        (this.cardForm.get('tooltips') as UntypedFormArray).clear({ emitEvent: false });
        card.tooltips?.forEach(() => {
          (this.cardForm.get('tooltips') as UntypedFormArray).push(
            new UntypedFormGroup({
              text: new UntypedFormControl('', Validators.required),
              regionIds: new UntypedFormControl([], Validators.required),
            }),
            { emitEvent: false }
          );
        });
      }

      if (card.caseMarkers) {
        (this.cardForm.get('caseMarkers') as UntypedFormArray).clear({ emitEvent: false });
        card.caseMarkers?.forEach(() => {
          (this.cardForm.get('caseMarkers') as UntypedFormArray).push(
            new UntypedFormGroup({
              text: new UntypedFormControl('', Validators.required),
              regionId: new UntypedFormControl('', Validators.required),
            }),
            { emitEvent: false }
          );
        });
      }

      this.cardForm.reset(undefined, { emitEvent: false });
      this.cardForm.patchValue(this.caseSpec.cards[this.selectedCardIndex], { emitEvent: false });
    }
  }

  solutionsControls(): AbstractControl[] {
    return (this.cardForm.get('solutions') as UntypedFormArray).controls;
  }

  followupsControls(): AbstractControl[] {
    return (this.cardForm.get('backFollowups') as UntypedFormArray).controls;
  }

  caseMarkersControls(): AbstractControl[] {
    return (this.cardForm.get('caseMarkers') as UntypedFormArray).controls;
  }

  tooltipsControls(): AbstractControl[] {
    return (this.cardForm.get('tooltips') as UntypedFormArray).controls;
  }

  onCardSelected(i: number | undefined): void {
    this.selectedCardIndex = i;
    this.updateControls();
  }

  onAddCardClicked(): void {
    this.caseSpec.cards.push({
      viewRegion: null,
      frontTitle: 'Bespielfrage',
      hint: '',
      backText: '',
      solutions: [],
      caseMarkers: [],
      tooltips: [],
      backFollowups: [],
      tags: [],
    });
    this.onCardSelected(this.caseSpec.cards.length - 1);
    this.caseSpecChange.emit(this.caseSpec);
  }

  onAddSolutionClicked(): void {
    (this.cardForm.get('solutions') as UntypedFormArray).push(
      new UntypedFormGroup({
        text: new UntypedFormControl('', Validators.required),
        regionIds: new UntypedFormControl([], Validators.required),
      })
    );
  }

  onAddFollowupClicked(): void {
    (this.cardForm.get('backFollowups') as UntypedFormArray).push(
      new UntypedFormGroup({
        text: new UntypedFormControl('', Validators.required),
        viewRegion: new UntypedFormGroup({
          regionId: new UntypedFormControl('', Validators.required),
        }),
      })
    );
  }

  onAddCaseMarkerClicked(): void {
    (this.cardForm.get('caseMarkers') as UntypedFormArray).push(
      new UntypedFormGroup({
        text: new UntypedFormControl('', Validators.required),
        regionId: new UntypedFormControl('', Validators.required),
      })
    );
  }

  onAddTooltipClicked(): void {
    (this.cardForm.get('tooltips') as UntypedFormArray).push(
      new UntypedFormGroup({
        text: new UntypedFormControl('', Validators.required),
        regionIds: new UntypedFormControl([], Validators.required),
      })
    );
  }

  drop($event: CdkDragDrop<CaseCard[]>): void {
    if (this.caseSpec.cards) {
      moveItemInArray(this.caseSpec.cards, $event.previousIndex, $event.currentIndex);
    }
    if (this.selectedCardIndex === $event.previousIndex) {
      this.onCardSelected($event.currentIndex);
    }
    this.caseSpecChange.emit(this.caseSpec);
  }

  onPopupOpened(): void {
    this.selectedRegionIndex = undefined;
    this.selectedRegion = undefined;
    this.ref.detectChanges();
  }

  onPopupAddRegionClicked($event: { position: [number, number] }): void {
    let new_id = this.caseSpec.regions.length + 1;

    // check for existing id
    while (this.caseSpec.regions.find(region => region.id === 'Klickregion ' + new_id) !== undefined) {
      new_id++;
    }

    this.caseSpec.regions = [
      ...this.caseSpec.regions,
      new CaseRegion({
        id: 'Klickregion ' + new_id,
        center: [Math.round($event.position[0]), Math.round($event.position[1])],
        radius: 400,
      }),
    ];

    this.selectedRegionIndex = this.caseSpec.regions.length - 1;
    this.selectedRegion = this.caseSpec.regions[this.selectedRegionIndex];
    this.ref.detectChanges();
    this.caseSpecChange.emit(this.caseSpec);
  }

  private updateAllRegionReferences(oldId: string, newId: string): void {
    // update regionIds for: caseMarkers, tooltips, solutions
    this.caseSpec.cards.forEach(card => {
      card.caseMarkers.forEach(caseMarker => {
        if (caseMarker.regionId === oldId) {
          caseMarker.regionId = newId;
        }
      });

      card.tooltips.forEach(tooltip => {
        tooltip.regionIds.forEach((id, index) => {
          if (id === oldId) {
            tooltip.regionIds[index] = newId;
          }
        });
      });

      card.solutions.forEach(solution => {
        solution.regionIds.forEach((id, index) => {
          if (id === oldId) {
            solution.regionIds[index] = newId;
          }
        });
      });
    });

    // also update regionIds for viewRegions: titleCard, caseCards, followups
    if (this.caseSpec.titleCard.viewRegion) {
      if (this.caseSpec.titleCard.viewRegion.regionId === oldId) {
        this.caseSpec.titleCard.viewRegion.regionId = newId;
      }
    }

    this.caseSpec.cards.forEach(card => {
      if (card.viewRegion) {
        if (card.viewRegion.regionId === oldId) {
          card.viewRegion.regionId = newId;
        }
      }

      card.backFollowups.forEach(followup => {
        if (followup.viewRegion) {
          if (followup.viewRegion.regionId === oldId) {
            followup.viewRegion.regionId = newId;
          }
        }
      });
    });
  }

  onRegionChanged($event: CaseRegion): void {
    if (this.selectedRegionIndex !== undefined) {
      if ($event.id !== this.caseSpec.regions[this.selectedRegionIndex].id) {
        this.updateAllRegionReferences(this.caseSpec.regions[this.selectedRegionIndex].id, $event.id);

        // titleCard viewRegion may have changed, patch
        this.titleCardForm.patchValue(
          {
            viewRegion: this.caseSpec.titleCard.viewRegion,
            backText: this.caseSpec.titleCard.backText,
          },
          { emitEvent: true }
        );

        this.updateControls();
      }
      this.caseSpec.regions[this.selectedRegionIndex] = $event;
      this.caseSpec.regions = [...this.caseSpec.regions];
      this.ref.detectChanges();
      this.caseSpecChange.emit(this.caseSpec);
    }
  }

  onClickRegionMapReady($event: { map: Map }): void {
    this.clickRegionMapViewMap = $event.map;
  }

  onRegionClicked($event: { regionId: string }): void {
    this.caseSpec.regions.forEach((region, index) => {
      if (region.id === $event.regionId) {
        this.selectedRegionIndex = index;
        this.selectedRegion = this.caseSpec.regions[this.selectedRegionIndex];
        this.ref.detectChanges();
      }
    });
  }

  onRegionMoved($event: { regionId: string; position: [number, number] }): void {
    this.caseSpec.regions.forEach(region => {
      if (region.id === $event.regionId) {
        const circularRegion = region as CaseRegion;
        circularRegion.center = $event.position;
        this.ref.detectChanges();
      }
    });
  }

  onRegionDeleteClicked($event: CaseRegion): void {
    this.caseSpec.regions = this.caseSpec.regions.filter(region => region.id !== $event.id);
    this.selectedRegionIndex = undefined;
    this.selectedRegion = undefined;
    this.ref.detectChanges();
    this.caseSpecChange.emit(this.caseSpec);
  }

  onRegionCloseClicked(): void {
    this.selectedRegionIndex = undefined;
    this.selectedRegion = undefined;
    this.ref.detectChanges();
  }

  onDeleteCaseMarkerClicked(i: number): void {
    if (this.selectedCardIndex !== undefined) {
      this.caseSpec.cards[this.selectedCardIndex].caseMarkers.splice(i, 1);
      this.caseSpec.cards = [...this.caseSpec.cards];
      this.updateControls();
    }
    this.caseSpecChange.emit(this.caseSpec);
  }

  onDeleteTooltipClicked(i: number): void {
    if (this.selectedCardIndex !== undefined) {
      this.caseSpec.cards[this.selectedCardIndex].tooltips.splice(i, 1);
      this.caseSpec.cards = [...this.caseSpec.cards];
      this.updateControls();
    }
    this.caseSpecChange.emit(this.caseSpec);
  }

  onDeleteSolutionClicked(i: number): void {
    if (this.selectedCardIndex !== undefined) {
      this.caseSpec.cards[this.selectedCardIndex].solutions.splice(i, 1);
      this.caseSpec.cards = [...this.caseSpec.cards];
      this.updateControls();
    }
    this.caseSpecChange.emit(this.caseSpec);
  }

  onDeleteFollowupClicked(i: number): void {
    if (this.selectedCardIndex !== undefined) {
      this.caseSpec.cards[this.selectedCardIndex].backFollowups.splice(i, 1);
      this.caseSpec.cards = [...this.caseSpec.cards];
      this.updateControls();
    }
    this.caseSpecChange.emit(this.caseSpec);
  }

  onDeleteCardClicked(): void {
    if (this.selectedCardIndex !== undefined) {
      this.caseSpec.cards = this.caseSpec.cards.filter((card, index) => index !== this.selectedCardIndex);
      if (this.selectedCardIndex > 0) {
        this.selectedCardIndex--;
      } else {
        this.selectedCardIndex = undefined;
      }
    }
    this.caseSpecChange.emit(this.caseSpec);
  }

  onAddTag(term: string): string {
    return term;
  }
}
